"use client";
import { SVGProps, useEffect, useRef } from "react";

export function QrCode({
  "data-hovered": hovered,
  className,
  ...rest
}: { "data-hovered"?: boolean } & SVGProps<SVGSVGElement>) {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (!ref.current) return;

    if (hovered) {
      animate();
    }
  }, [hovered]);

  const animate = () => {
    ref?.current?.animate(
      [
        { transform: "translateY(0)" },
        { transform: "translateY(4px)" },
        { transform: "translateY(-4px)" },
        { transform: "translateY(0)" },
      ],
      {
        duration: 500,
      },
    );
  };

  return (
    <div
      className="flex cursor-pointer select-none items-center justify-center"
      onMouseEnter={animate}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-scan-line"
        {...rest}
      >
        <path d="M3 7V5a2 2 0 0 1 2-2h2" />
        <path d="M17 3h2a2 2 0 0 1 2 2v2" />
        <path d="M21 17v2a2 2 0 0 1-2 2h-2" />
        <path d="M7 21H5a2 2 0 0 1-2-2v-2" />
        <path d="M7 12h10" ref={ref} />
      </svg>
    </div>
  );
}
